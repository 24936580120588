// 底部2隐私协议
<template>
  <div class="main">
    <div class="top">
      <div class="top-top">
        <div class="top-liney"></div>
        <div class="top-title">Privacy Policy</div>
      </div>
      <div class="top-text">
        CINDRA Ltd ("We", "our", "us") are committed to protecting and
        respecting your privacy.
      </div>
      <div class="top-text">
        This Privacy Policy sets out the basis on which any personal data we
        collect from you, or that you provide to us, will be processed by us.
        Please read the following carefully to understand our views and
        practices regarding your personal data and how we will treat it. By
        visiting
        <el-link type="primary" @click="toUrl()">www.usblindsmall.com</el-link>
        you are accepting and consenting to the practices described in this
        Privacy Policy.
      </div>
      <div class="top-text">
        For the purpose of the Data Protection Act 1998 (the "Act"), the data
        controller is CINDRA Ltd of No. 23, Ruhu West Road, Simen, Yuyao,
        Ningbo, China.
      </div>
    </div>
    <!-- 1 -->
    <div class="content">
      <div class="content-title">Information we collect from you</div>
      <div class="top-text">
        We will collect and process the following data about you:
      </div>

      <div class="content-text">
        <div class="s-point"></div>
        <div class="content-text-r">
          <span class="font-w">Information you give us.</span>
          This is information about you that you give us by filling in forms on
          our site
          <el-link type="primary" @click="toUrl()">www.usblindsmall.com</el-link
          >. ("our site") or by corresponding with us by phone, e-mail or
          otherwise. It includes information you provide when you register to
          use our site, search for a product, place an order on our site,
          participate in any discussion boards or other social media functions
          on our site, enter a competition, promotion or survey, or apply for a
          trade account (if applicable) and when you report a problem with our
          site. The information you give us may include your name, address,
          e-mail address and phone number, financial and credit card
          information, personal description and if applicable photograph and
          trade references (if applicable).
        </div>
      </div>
      <div class="content-text">
        <div class="s-point"></div>
        <div class="content-text-r">
          <span class="font-w">Information we collect about you.</span>
          With regard to each of your visits to our site we will automatically
          collect the following information:
        </div>
      </div>
      <div class="content-text-copy">
        <div class="k-point"></div>
        <div class="content-text-r">
          Technical information, including the Internet protocol (IP) address
          used to connect your computer to the Internet, your login information,
          browser type and version, time zone setting, browser plug-in types and
          versions, operating system and platform;
        </div>
      </div>
      <div class="content-text-copy">
        <div class="k-point"></div>
        <div class="content-text-r">
          Information about your visit, including the full Uniform Resource
          Locators (URL), clickstream to, through and from our site (including
          date and time), products you viewed or searched for’ page response
          times, download errors, length of visits to certain pages, page
          interaction information (such as scrolling, clicks, and mouse-overs),
          methods used to browse away from the page and any phone number used to
          call our customer service number.
        </div>
      </div>
      <div class="content-text">
        <div class="s-point"></div>
        <div class="content-text-r">
          <span class="font-w">Information we receive from other sources.</span>
          This is information we receive about you if you use any of the other
          websites we operate or the other services we provide. We work closely
          with third parties (including, for example, business partners,
          sub-contractors in technical, payment and delivery services,
          advertising networks, analytics providers, search information
          providers, credit reference agencies) and may receive information
          about you from them.
        </div>
      </div>
    </div>
    <!-- 2 -->
    <div class="content">
      <div class="content-title">Cookies</div>
      <div class="fot-text">
        Our website uses cookies to distinguish you from other users of our
        website. This helps us to provide you with a good experience when you
        browse our website and also allows us to improve our site. For detailed
        information on the cookies we use and the purposes for which we use them
        see our <span class="font-w">Cookie Policy</span>
        <el-link type="primary" @click="toUrlCookies()"
          >www.usblindsmall.com/cookies</el-link
        >.
      </div>
    </div>
    <!-- 3 -->
    <div class="content">
      <div class="content-title">Uses made of the information</div>
      <div class="top-text">
        We use information held about you in the following ways:
      </div>

      <div class="content-text">
        <div class="s-point"></div>
        <div class="content-text-r">
          <span class="font-w">Information you give to us.</span>
          We will use this information:
        </div>
      </div>
      <div class="content-text-copy">
        <div class="k-point"></div>
        <div class="content-text-r">
          To carry out our obligations arising from any contracts entered into
          between you and us and to provide you with the information, products
          and ser vices that you request from us;
        </div>
      </div>
      <div class="content-text-copy">
        <div class="k-point"></div>
        <div class="content-text-r">
          To provide you with information about other goods and services we
          offer that are similar to those that you have already purchased or
          enquired about;
        </div>
      </div>
      <div class="content-text-copy">
        <div class="k-point"></div>
        <div class="content-text-r">
          To provide you, or permit selected third parties to provide you, with
          information about goods or services we feel may interest you. If you
          are an existing customer, we will only contact you by electronic means
          (e-mail or SMS) with information about goods and services similar to
          those which were the subject of a previous sale or negotiations of a
          sale to you. If you are a new customer, and where we permit selected
          third parties to use your data, we (or they) will contact you by
          electronic means only if you have consented to this. If you do not
          want us to use your data in this way, or to pass your details on to
          third parties for marketing purposes, please tick the relevant box
          situated on the form on which we collect your data (the order form);
        </div>
      </div>
      <div class="content-text-copy">
        <div class="k-point"></div>
        <div class="content-text-r">
          To notify you about changes to our service;
        </div>
      </div>
      <div class="content-text-copy">
        <div class="k-point"></div>
        <div class="content-text-r">
          To ensure that content from our site is presented in the most
          effective manner for you and for your computer.
        </div>
      </div>
      <div class="content-text">
        <div class="s-point"></div>
        <div class="content-text-r">
          <span class="font-w">Information we collect about you.</span>
          We will use this information:
        </div>
      </div>
      <div class="content-text-copy">
        <div class="k-point"></div>
        <div class="content-text-r">
          To administer our site and for internal operations, including
          troubleshooting, data analysis, testing, research, statistical and
          survey purposes;
        </div>
      </div>
      <div class="content-text-copy">
        <div class="k-point"></div>
        <div class="content-text-r">
          To improve our site to ensure that content is presented in the most
          effective manner for you and for your computer;
        </div>
      </div>
      <div class="content-text-copy">
        <div class="k-point"></div>
        <div class="content-text-r">
          To allow you to participate in interactive features of our service,
          when you choose to do so;As part of our efforts to keep our site safe
          and secure;
        </div>
      </div>
      <div class="content-text-copy">
        <div class="k-point"></div>
        <div class="content-text-r">
          To measure or understand the effectiveness of advertising we serve to
          you and others, and to deliver relevant advertising to you;
        </div>
      </div>
      <div class="content-text-copy">
        <div class="k-point"></div>
        <div class="content-text-r">
          To make suggestions and recommendations to you and other users of our
          site about goods or services that may interest you or them.
        </div>
      </div>
      <div class="content-text">
        <div class="s-point"></div>
        <div class="content-text-r">
          <span class="font-w">Information we receive from other sources.</span>
          We will combine this information with information you give to us and
          information we collect about you. We will use this information and the
          combined information for the purposes set out above (depending on the
          types of information we receive).
        </div>
      </div>
    </div>
    <!-- 4 -->
    <div class="content">
      <div class="content-title">Disclosure of your information</div>
      <div class="top-text">
        You agree that we have the right to share your personal information
        with:
      </div>
      <div class="content-text">
        <div class="s-point"></div>
        <div class="content-text-r">
          Any member of our group, which means our subsidiaries, or any
          associated companies, as defined in section 1159 of the UK Companies
          Act 2006.
        </div>
      </div>
      <div class="content-text">
        <div class="s-point"></div>
        <div class="content-text-r">Selected third parties including:</div>
      </div>
      <div class="content-text-copy">
        <div class="k-point"></div>
        <div class="content-text-r">
          Business partners, suppliers and sub-contractors for the performance
          of any contract we enter into with them or you;
        </div>
      </div>
      <div class="content-text-copy">
        <div class="k-point"></div>
        <div class="content-text-r">
          Credit reference agencies for the purpose of assessing your credit
          score where this is a condition of us entering into a contract with
          you.
        </div>
      </div>
      <div class="content-text">
        <div class="s-point"></div>
        <div class="content-text-r">
          We will disclose your personal information to third parties:
        </div>
      </div>
      <div class="content-text-copy">
        <div class="k-point"></div>
        <div class="content-text-r">
          In the event that we sell or buy any business or assets, in which case
          we will disclose your personal data to the prospective seller or buyer
          of such business or assets.
        </div>
      </div>
      <div class="content-text-copy">
        <div class="k-point"></div>
        <div class="content-text-r">
          If CINDRA Ltd or substantially all of its assets are acquired by a
          third party, in which case personal data held by it about its
          customers will be one of the transferred assets.
        </div>
      </div>
      <div class="content-text-copy">
        <div class="k-point"></div>
        <div class="content-text-r">
          If we are under a duty to disclose or share your personal data in
          order to comply with any legal obligation, or in order to enforce or
          apply our terms of use
          <el-link type="primary" @click="toUrl()"
            >www.usblindsmall.com</el-link
          >
          and other agreements with you; or to protect the rights, property, or
          safety of CINDRA Ltd, our cus tomers, or others. This includes
          exchanging information with other companies and organisations for the
          purposes of fraud protection and credit risk reduc tion.
        </div>
      </div>
    </div>
    <!-- 5 -->
    <div class="content">
      <div class="content-title">Where we store your personal data</div>
      <div class="fot-text">
        All information you provide to us is stored on our secure servers. Any
        payment transactions will be encrypted using SSL technology. Where we
        have given you (or where you have chosen) a password which enables you
        to access certain parts of our site, you are responsible for keeping
        this password confidential. We ask you not to share a password with
        anyone.
      </div>
      <div class="fot-text">
        Unfortunately, the transmission of information via the internet is not
        completely secure. Although we will do our best to protect your personal
        data, we cannot guarantee the security of your data transmitted to our
        site; any transmission is at your own risk. Once we have received your
        information, we will use strict procedures and security features to try
        to prevent unauthorised access.
      </div>
    </div>
    <!-- 6 -->
    <div class="content">
      <div class="content-title">Your rights</div>
      <div class="fot-text">
        You have the right to ask us not to process your personal data for
        marketing purposes. We will usually inform you (before collecting your
        data) if we intend to use your data for such purposes or if we intend to
        disclose your information to any third party for such purposes. You can
        exercise your right to prevent such processing by checking certain boxes
        on the forms we use to collect your data. You can also exercise the
        right at any time by contacting us at bailian@zz4life.com.
      </div>
      <div class="fot-text">
        Our site may, from time to time, contain links to and from the websites
        of our partner networks, advertisers and affiliates. If you follow a
        link to any of these websites, please note that these websites have
        their own privacy policies and that we do not accept any responsibility
        or liability for these policies. Please check these policies before you
        submit any personal data to these websites.
      </div>
    </div>
    <!-- 7 -->
    <div class="content">
      <div class="content-title">Access to information</div>
      <div class="fot-text">
        The Act gives you the right to access information held about you. Your
        right of access can be exercised in accordance with the Act. Any access
        request will be subject to a fee of £10 to meet our costs in providing
        you with details of the information we hold about you.
      </div>
    </div>
    <!-- 8 -->
    <div class="content">
      <div class="content-title">Changes to privacy policy</div>
      <div class="fot-text">
        Any changes we make to our privacy policy in the future will be posted
        on this page and, where appropriate, notified to you by e-mail. Please
        check back frequently to see any updates or changes to our privacy
        policy.
      </div>
    </div>
    <!-- 9 -->
    <div class="content">
      <div class="content-title">Contact</div>
      <div class="fot-text">
        Any questions, comments and requests regarding this privacy policy
        should be addressed to bailian@zz4life.com.
      </div>
    </div>
    <div class="fot-text"></div>
  </div>
</template>
<script>
import { usVisitLogInsert } from "@/api/home.js";
export default {
  name: "Privacy",
  data() {
    return {
      ossPrefix: "https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/",
    };
  },
  activated() {
    // console.log("activated");
    this.addVisitLogInsert();
  },
  methods: {
    toUrl() {
      const url = "https://www.usblindsmall.com";
      // window.open(url, "_blank");
      window.location.href = url;
    },
    toUrlCookies() {
      // 假设我们要打开的网页是 https://www.usblindsmall.com，指定页面的URL是 https://www.usblindsmall.com/#/cookies
      const url = "https://www.usblindsmall.com/#/cookies";
      // window.open(url, "_blank");
      window.location.href = url;
    },
    addVisitLogInsert() {
      let website = window.location.href;
      let websiteTitle = "Privacy";
      // console.log(website, websiteTitle);
      usVisitLogInsert(website, websiteTitle, 0).then(() => {});
    },
  },
};
</script>
<style scoped>
.main {
  width: 100%;
  box-sizing: border-box;
}
.top {
  width: 1110px;
  margin: 51px auto;
}
.top-liney {
  width: 1px;
  height: 60px;
  background-color: #293744;
}

.top-top {
  display: flex;
  align-items: flex-end;
  margin-bottom: 38px;
}
.top-title {
  font-size: 34px;
  font-weight: bold;
  color: #262626;
  margin-left: 16px;
}
.top-text {
  font-size: 16px;
  font-weight: 400;
  color: #262626;
  margin-bottom: 17px;
}
.content {
  width: 1110px;
  margin: 0 auto 51px;
}

.content-title {
  font-size: 20px;
  font-weight: 500;
  color: #262626;
  margin-bottom: 16px;
}
.content-text {
  width: 100%;
  font-size: 15px;
  font-weight: 400;
  color: #262626;
  margin-bottom: 10px;
  display: flex;
}
.content-text-copy {
  width: 100%;
  padding: 0 0 0 28px;
  box-sizing: border-box;
  font-size: 15px;
  font-weight: 400;
  color: #262626;
  margin-bottom: 8px;
  display: flex;
}
.k-point {
  width: 8px;
  height: 8px;
  border: 1px solid #293744;
  border-radius: 50%;
  margin: 7px 13px;
}
.font-w {
  font-weight: bold;
}
.s-point {
  width: 8px !important;
  height: 8px;
  background-color: #293744;
  border-radius: 50%;
  margin: 7px 13px;
}
.content-text-r {
  width: calc(100% - 35px);
}
.fot {
  width: 1110px;
  margin: 0 auto 51px;
}
.fot-text {
  font-size: 15px;
  font-weight: 400;
  color: #262626;
  margin-bottom: 10px;
}
</style>
